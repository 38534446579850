import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const GA_TRACKING_ID='G-4XFD4NCQZT'

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    //if (!window.location.href.includes("localhost")) {
        gtag('js', new Date());
        gtag('config', GA_TRACKING_ID, { 'app_name': 'eden-garden' });

        setInitialized(true)
//      }
  }, []);

  useEffect(() => {
    if (initialized) {
      gtag('event', 'screen_view', { 'screen_name': location.pathname + location.search});
    }
  }, [initialized, location]);
};

export default usePageTracking;
