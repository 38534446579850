import { createStore, applyMiddleware  } from "redux";
import { createLogger } from 'redux-logger'
import  thunk  from 'redux-thunk';

import rootReducer from "./reducers";

const loggerMiddleware = createLogger()

let DEBUG = false//(process.env.NODE_ENV !== 'production')


let middleware:Array<any> = [
    thunk, // lets us dispatch() functions
]
if(DEBUG){
    middleware = [...middleware, loggerMiddleware]
}

const store = createStore(
   rootReducer,
   applyMiddleware(...middleware)
);


export default store;
