import React from 'react';
import '../../Animation.scss'

function Loading() {
    return (
        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
            <img src={"/logo512.png"} alt="logo" style={{ height: "20%", width:"auto" }} />
            <div  className="anim-pulse" style={{fontSize:"6vh"}}>
                Chargement...
            </div>
        </div>
    )
}


export default Loading;
