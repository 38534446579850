


import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
//import logo from './logo.svg';
import './App.scss';
import Loading from 'components/utils/Loading'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

//import useUser from 'utils/hooks/useUser';
import usePageTracking from './utils/hooks/usePageTracking';
import useFacebookAuth from 'utils/hooks/useFacebookAuth';


import './decs.d.ts'

const PrivateRoute = React.lazy(() => import('components/utils/PrivateRoute'));
const LoginPage = React.lazy(() => import('components/login/LoginPage'));
const HomePage = React.lazy(() => import('components/main/HomePage'));
const CGUPage = React.lazy(() => import('components/main/CGUPage'));
const PrivacyPage = React.lazy(() => import('components/main/PrivacyPage'));
const LandingPage = React.lazy(() => import('components/main/LandingPage'));
const SoilScanner = React.lazy(() => import('components/tools/SoilScanner'));


function App() {
  //let { logoutUser } = useUser()
  usePageTracking()
  useFacebookAuth("574174183521611", true)
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<Loading/>}>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        limit={5}
      />

      <Switch>
        {/* removing trailing slash         */}
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/landing">
          <LandingPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/home">
          <HomePage />
        </PrivateRoute>
        <Route path="/CGU">
          <CGUPage />
        </Route>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
        <Route path="/public/soil">
          <SoilScanner />
        </Route>
        <Redirect from="/" to="/landing" />
      </Switch>
    </Suspense>
  );
}

export default App;
