import { useState, useEffect, useRef } from 'react';

// Hook
const useFacebookAuth = function(client_id:string, initialize:boolean=false):[boolean,boolean,any|undefined] {
  interface State {
    loaded: boolean,
    error: boolean,
    facebookAuth?:fb.FacebookStatic,
  }

  // Keeping track of script loaded and error state
  const defaultState:State = {
    loaded: false,
    error: false,
  }
  const [state, setState] = useState(defaultState);
  const isFB = (window as any).FB !== undefined
  const isMountedRef = useRef<boolean | null>(null);


  useEffect(
    () => {
      if(isMountedRef.current!==null){isMountedRef.current=true}

      if(isFB){
        //console.log("reuse FB")
        //if(isMountedRef?.current)setState({loaded:true, error:false, facebookAuth:(window as any).FB})
        setState({loaded:true, error:false, facebookAuth:(window as any).FB})
      }else if(initialize){
        (window as any).fbAsyncInit = function() {
          FB.init({
            appId      : client_id,
            xfbml      : true,
            version    : 'v7.0'
          });
          FB.AppEvents.logPageView();
          setState({loaded:true, error:false, facebookAuth:FB})
        };

        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          (js as any).src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode!.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }else{
        //console.log("skip init")
      }

      // Cleanup function
      return () => {
        isMountedRef.current = false
      };
    },
    [client_id, isFB, initialize] // Only re-run effect if client_id or script_loaded changes
  );


  return [state.loaded, state.error, state.facebookAuth];
}


export default useFacebookAuth
